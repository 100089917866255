import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Help from "@material-ui/icons/Help";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

const styles = theme => ({
    icon: {
        width: "50px",
        height: "100%"
    },
    typography: {
        display: "inline-block"
    }
});

class InfoDialog extends React.Component {
    state = {
        open: false
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes } = this.props;
        const { fullScreen } = this.props;

        return (
            <div>
                <IconButton
                    className={classes.icon}
                    onClick={this.handleClickOpen}
                >
                    <Help />
                </IconButton>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullScreen={fullScreen}
                >
                    <DialogTitle>{"What is sol?"}</DialogTitle>

                    <DialogContent>
                        <DialogContentText>
                            Sol is a Mars solar day; that is, a Mars-day. The
                            duration of this day-and-night cycle (one day) is 24
                            hours, 39 minutes and 35.244 seconds.
                        </DialogContentText>
                    </DialogContent>

                    <DialogTitle>{"Which cameras can I choose?"}</DialogTitle>

                    <DialogContent>
                        <DialogContentText>
                            <Typography
                                variant="subtitle1"
                                className={classes.typography}
                            >
                                FHAZ
                            </Typography>
                            {" and "}
                            <Typography
                                variant="subtitle1"
                                className={classes.typography}
                            >
                                RHAZ
                            </Typography>
                            {" - front and rear hazard avoidance cameras, "}
                            <Typography
                                variant="subtitle1"
                                className={classes.typography}
                            >
                                MAST
                            </Typography>
                            {" - Mast Camera, "}
                            <Typography
                                variant="subtitle1"
                                className={classes.typography}
                            >
                                CHEMCAM
                            </Typography>
                            {" - Chemistry and Camera Complex, "}
                            <Typography
                                variant="subtitle1"
                                className={classes.typography}
                            >
                                MAHLI
                            </Typography>
                            {" - Mars Hand Lens Imager, "}
                            <Typography
                                variant="subtitle1"
                                className={classes.typography}
                            >
                                MARDI
                            </Typography>
                            {" - Mars Descent Imager, "}
                            <Typography
                                variant="subtitle1"
                                className={classes.typography}
                            >
                                NAVCAM
                            </Typography>
                            {" - Navigation Camera, "}
                            <Typography
                                variant="subtitle1"
                                className={classes.typography}
                            >
                                PANCAM
                            </Typography>
                            {" - Panoramic Camera, "}
                            <Typography
                                variant="subtitle1"
                                className={classes.typography}
                            >
                                MINITES
                            </Typography>
                            {" - Miniature Thermal Emission Spectrometer."}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

InfoDialog.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
  };

export default compose (
    withStyles(styles),
    withMobileDialog()
)(InfoDialog);
