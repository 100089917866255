import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import RoversHeader from "../components/RoversHeader";
import ExploreButtons from "../components/ExploreButtons";
import Progress from "../components/Progress";
import Paginate from "../components/Paginate";
import axios from "axios";
import _ from "lodash";
import LightBox from "../components/LightBox";

const styles = theme => ({
    header: {
        display: "flex",
        "flex-wrap": "wrap",
        "align-items": "center",
        "justify-content": "space-between",
        padding: theme.spacing.unit
    },
    progress: {
        height: "100vh"
    }
});

class Explore extends Component {
    state = {
        camera: "",
        sol: "0",
        currentSol: "0",
        cameraOptions: [],
        manifest: {},
        noSol: true,
        photos: [],
        currentPhotos: [],
        totalPhotos: 0,
        page: 0,
        pageSize: 24,
        loading: true
    };

    API_KEY = "yYdPo05wwdAPcMX8it2dkVM55J7itsLa83G1zW0Z";

    componentDidMount = () => {
        axios
            .get(
                `https://api.nasa.gov/mars-photos/api/v1/manifests/${
                    this.props.match.params.rover
                }`,
                {
                    params: {
                        api_key: this.API_KEY
                    }
                }
            )
            .then(res => {
                this.setState(
                    {
                        manifest: res.data,
                        sol: String(
                            res.data.photo_manifest.photos[
                                this.getRandomSol(res)
                            ].sol
                        )
                    },
                    () => {
                        this.setOptions();
                        this.handleClick();
                    }
                );
            })
            .catch(function(error) {
                console.log(error);
            });
    };

    handlePageChange = page => {
        this.setState({ page: page }, () => this.createGallery());
    };

    handleClick = () => {
        if (this.state.currentSol === this.state.sol)
            this.setState({ page: 0 }, () => this.createGallery());
        else {
            this.setState({ loading: true });
            axios
                .get(
                    `https://api.nasa.gov/mars-photos/api/v1/rovers/${
                        this.props.match.params.rover
                    }/photos`,
                    {
                        params: {
                            api_key: this.API_KEY,
                            sol: this.state.sol
                        }
                    }
                )
                .then(res => {
                    this.setState(
                        {
                            photos: res.data.photos,
                            loading: false,
                            currentSol: this.state.sol,
                            page: 0
                        },
                        () => this.createGallery()
                    );
                })
                .catch(function(error) {
                    console.log(error);
                });
        }
    };

    getRandomSol = res =>
        Math.floor(Math.random() * res.data.photo_manifest.photos.length - 1);

    handleChange = event => {
        const target = event.target.name;
        this.setState({ [event.target.name]: event.target.value }, () => {
            if (target === "sol") this.setOptions();
        });
    };

    setOptions = () => {
        const cameras = _.find(this.state.manifest.photo_manifest.photos, {
            sol: parseInt(this.state.sol)
        });

        if (cameras == null)
            this.setState({
                noSol: true
            });
        else {
            const ifReapeats = _.findIndex(cameras.cameras, el => {
                return el === this.state.camera;
            });

            this.setState({
                cameraOptions: cameras.cameras,
                noSol: false,
                ...(ifReapeats === -1 ? { camera: "" } : {})
            });
        }
    };

    toHttps = src => src[4]==='s' ? src : "https" + src.slice(4);

    createGallery = () => {
        let data = this.state.photos;

        if (this.state.camera !== "")
            data = _.filter(data, { camera: { name: this.state.camera } });
        const totalPhotos = data.length;

        data = _.slice(
            data,
            this.state.page * this.state.pageSize,
            (this.state.page + 1) * this.state.pageSize
        );

        data = data.map(photo => ({
            src: this.toHttps(photo.img_src),
            thumbnail: this.toHttps(photo.img_src),
            thumbnailWidth: 320,
            thumbnailHeight: 174
        }));

        this.setState({
            currentPhotos: data,
            totalPhotos: totalPhotos
        });
    };

    render() {
        const { classes } = this.props;

        if (_.isEmpty(this.state.manifest))
            return (
                <div className={classes.progress}>
                    <Progress />
                </div>
            );
        else
            return (
                <div className={classes.root}>
                    <div className={classes.header}>
                        <RoversHeader
                            manifest={this.state.manifest}
                            {...this.props}
                        />

                        <ExploreButtons
                            sol={this.state.sol}
                            handleChange={this.handleChange}
                            cameraOptions={this.state.cameraOptions}
                            noSol={this.state.noSol}
                            handleClick={this.handleClick}
                            camera={this.state.camera}
                        />
                    </div>
                    {this.state.loading ? (
                        <Progress />
                    ) : (
                        <div>
                            <LightBox images={this.state.currentPhotos} />
                            <Paginate
                                totalPhotos={this.state.totalPhotos}
                                itemsOnPage={this.state.pageSize}
                                pageChange={this.handlePageChange}
                                page={this.state.page}
                            />
                        </div>
                    )}
                </div>
            );
    }
}
export default withStyles(styles)(Explore);
