import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
    colors: {
        white: "rgba(255, 255, 255, 0.87)"
    },
    typography: {
        useNextVariants: true,
        h3: {
            fontWeight: 200,
            color: "rgba(255, 255, 255, 0.87)"
        }
    },
    palette: {
        type: "dark"
    },
    overrides: {
        MuiOutlinedInput: {
            root: {
                '&$focused $notchedOutline': {
                    borderColor: '#fff',
                    borderWidth: 1,
                },
            },
        },
        MuiFormLabel: {
            root: {
                '&$focused': {
                    color: '#fff'
                }
            }
        },
    },
    Link: {
        color: 'inherit',
        'text-decoration': 'none',
        'outline': 'none'
    },
    breakpoints: {
        values: {
            sm: 450
        }
    }
});
