import React, { Component } from "react";
import "./parallax.scss";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
        borderColor: "#fff",
        color: "#fff",
        "border-radius": 0,
        "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.2)"
        }
    },
    parallax: {
        display: "flex",
        "flex-direction": "column",
        "justify-content": "center",
        overflow: "hidden",
        height: "100vh",
    },
    control: {
        "text-align": "center"
    },
    buttons: {
        display: "flex",
        "justify-content": "center",
        "flex-wrap": "wrap"
    },
    Link: theme.Link
});

class Main extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.parallax + " parallax"}>
                <div id="stars1" />
                <div id="stars2" />

                <div className={classes.control}>
                    <Typography variant="h3" gutterBottom>
                        Explore Mars with
                    </Typography>

                    <div className={classes.buttons}>
                        <Link to="/explore/curiosity" className={classes.Link}>
                            <Button
                                variant="outlined"
                                className={classes.button}
                            >
                                Curiosity
                            </Button>
                        </Link>

                        <Link to="/explore/opportunity" className={classes.Link}>
                            <Button
                                variant="outlined"
                                className={classes.button}
                            >
                                Opportunity
                            </Button>
                        </Link>

                        <Link to="/explore/spirit" className={classes.Link}>
                            <Button
                                variant="outlined"
                                className={classes.button}
                            >
                                Spirit
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}
export default withStyles(styles)(Main);
