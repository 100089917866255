import FormControl from "@material-ui/core/FormControl";
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import PropTypes from 'prop-types';

const styles = theme => ({
    root: {},
    formControl: {
        margin: theme.spacing.unit * 2,
        width: 100
    },
    select: {
        "&:after": {
            borderColor: "#fff"
        }
    }
});

class OutlinedSelect extends Component {
    handleChange = event => {
        this.props.handleChange(event);
    };

    render() {
        const menuItems = this.props.cameras
            ? this.props.cameras.map(camera => (
                  <MenuItem key={camera} value={camera}>
                      {camera}
                  </MenuItem>
              ))
            : null;

        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <FormControl
                    className={classes.formControl}
                    disabled={this.props.noSol}
                >
                    <InputLabel htmlFor={this.props.name}>
                        {this.props.name}
                    </InputLabel>
                    <Select
                        value={this.props.state}
                        onChange={this.handleChange}
                        className={classes.select}
                        inputProps={{
                            name: this.props.name,
                            id: this.props.name
                        }}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {menuItems}
                    </Select>
                </FormControl>
            </div>
        );
    }
}
export default withStyles(styles)(OutlinedSelect);

OutlinedSelect.propTypes = {
    state: PropTypes.string,
    name: PropTypes.string,
    handleChange: PropTypes.func,
    cameras: PropTypes.array,
    noSol: PropTypes.bool
}
