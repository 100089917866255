import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Input from "../components/Input";
import Button from "@material-ui/core/Button";
import Warning from "@material-ui/icons/Warning";
import OutlinedSelect from "../components/OutlinedSelect";
import InfoDialog from "../components/InfoDialog";
import Tooltip from "@material-ui/core/Tooltip";

const styles = theme => ({
    root: {
        display: "flex",
        "align-items": "center",
        "flex-wrap": "wrap",
        'margin-left': '15px'
    },
    buttons: {
        display: "flex",
        "align-items": "center"
    },
    button: {
        'margin-left': '15px',
        margin: theme.spacing.unit,
        borderColor: theme.colors.white,
        color: theme.colors.white,
        "border-radius": 0,
        "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.2)"
        }
    },
    icon: {
        color: theme.colors.white,
        "font-size": "24px"
    },
});

class ExploreButtons extends Component {
    handleChange = event => this.props.handleChange(event);

    handleClick = event => this.props.handleClick(event);

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.buttons}>
                    {this.props.noSol && (
                        <Tooltip title="No photos were taken this sol">
                            <Warning className={classes.icon} />
                        </Tooltip>
                    )}

                    <Input
                        state={this.props.sol}
                        name={"sol"}
                        handleChange={this.handleChange}
                    />

                    <OutlinedSelect
                        state={this.props.camera}
                        name={"camera"}
                        handleChange={this.props.handleChange}
                        cameras={this.props.cameraOptions}
                        noSol={this.props.noSol}
                    />
                </div>

                <div className={classes.buttons}>
                    <Button
                        variant="outlined"
                        className={classes.button}
                        onClick={this.handleClick}
                        disabled={this.props.noSol}
                    >
                        Explore!
                    </Button>

                    <InfoDialog />
                </div>
            </div>
        );
    }
}
export default withStyles(styles)(ExploreButtons);

ExploreButtons.propTypes = {
    sol: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    cameraOptions: PropTypes.array.isRequired,
    noSol: PropTypes.bool.isRequired,
    handleClick: PropTypes.func.isRequired,
    camera: PropTypes.string.isRequired
};
