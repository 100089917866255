import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Link } from "react-router-dom";
import MoreVert from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

const styles = theme => ({
    icon: {
        color: theme.colors.white,
        "font-size": "30px"
    },
    line: {
        display: "flex",
        "align-items": "center"
    },
    description: {
        margin: "0 30px"
    },
    iconButton: {
        padding: 0
    },
    roversName: {
        [theme.breakpoints.down("xs")]: {
            "font-size": "2.3rem"
        }
    }
});

class RoversHeader extends Component {
    blogLink = rover => {
        if (rover === "curiosity")
            return "https://mars.nasa.gov/msl/mission/mars-rover-curiosity-mission-updates/";
        else if (rover === "opportunity")
            return "https://mars.nasa.gov/mer/mission/status_opportunityAll.html";
        else
            return "https://www.space.com/35168-spirit-mars-rover-13th-anniversary.html";
    };

    render() {
        const { classes } = this.props;
        const manifest = this.props.manifest.photo_manifest;
        const rover = this.props.match.params.rover;

        return (
            <div>
                <div className={classes.line}>
                    <Link to="/">
                        <IconButton className={classes.iconButton}>
                            <KeyboardArrowLeft className={classes.icon} />
                        </IconButton>
                    </Link>
                    <Typography variant="h3" className={classes.roversName}>
                        {manifest.name.toUpperCase()}
                    </Typography>

                    <a href={this.blogLink(rover)}>
                        <Tooltip title="Mission updates">
                            <IconButton className={classes.iconButton}>
                                <MoreVert className={classes.icon} />
                            </IconButton>
                        </Tooltip>
                    </a>
                </div>
                <Typography variant="body1" className={classes.description}>
                    {"On Mars since "}
                    {manifest.landing_date}
                </Typography>
                <Typography variant="body1" className={classes.description}>
                    {manifest.max_sol}
                    {" Sols"}
                </Typography>
            </div>
        );
    }
}
export default withStyles(styles)(RoversHeader);

RoversHeader.propTypes = {
    manifest: PropTypes.object
};
