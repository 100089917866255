import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Main from "./pages/Main/Main";
import Explore from "./pages/Explore";
import { theme } from "./Themes";
import { MuiThemeProvider } from "@material-ui/core/styles";

const styles = theme => ({
    root: {
        margin: "0 auto",
    },
});

class App extends Component {
    render() {
        const { classes } = this.props;
        return (
            <MuiThemeProvider theme={theme}>
                <BrowserRouter>
                    <div className={classes.root}>
                        <Switch>
                            <Route exact path="/" component={Main} />
                            <Route path="/explore/:rover" component={Explore} />
                        </Switch>
                    </div>
                </BrowserRouter>
            </MuiThemeProvider>
        );
    }
}
export default withStyles(styles)(App);
