import PropTypes from "prop-types";
import React from "react";
import Gallery from "react-grid-gallery";

class LightBox extends React.Component {
    render() {
        return (
            <div
                style={{
                    minHeight: "1px",
                    width: "100%",
                    overflow: "hidden",
                }}
            >
                <Gallery
                    images={this.props.images}
                    enableImageSelection={false}
                />
            </div>
        );
    }
}
export default LightBox;

LightBox.propTypes = {
    images: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string.isRequired,
            thumbnail: PropTypes.string.isRequired,
            srcset: PropTypes.array,
            caption: PropTypes.string,
            thumbnailWidth: PropTypes.number.isRequired,
            thumbnailHeight: PropTypes.number.isRequired
        })
    ).isRequired
};

