import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PropTypes from 'prop-types';

const styles = theme => ({
    root: {},
    textField: {
        margin: theme.spacing.unit * 2,
        width: 60,
        "&:after": {
            backgroundColor: "#4CAF50"
        }
    },
    underline: {
        '&:after': {
          borderColor: '#fff',
        },
    }
});

class Input extends Component {
    handleChange = event => {
        this.props.handleChange(event);
    };

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <TextField
                    id={this.props.name}
                    name={this.props.name}
                    label={this.props.name}
                    value={this.props.state}
                    onChange={this.handleChange}
                    type="number"
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true
                    }}
                    margin="normal"
                    InputProps={{
                        classes: { underline: classes.underline }
                    }}
                />
            </div>
        );
    }
}
export default withStyles(styles)(Input);

Input.propTypes = {
    state: PropTypes.string,
    name: PropTypes.string,
    handleChange: PropTypes.func
}
