import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const styles = theme => ({
    root: {
        flexGrow: 1,
        display: "flex",
        "flex-direction": "column",
        "justify-content": "center",
        height: "100%",
        overflow: "hidden"
    },
    linearColorPrimary: {
        backgroundColor: "#fff"
    },
    linearBarColorPrimary: {
        backgroundColor: "#03030a"
    },
    progress: {
        display: "block",
    }
});

function Progress(props) {
    const { classes } = props;
    return (
        <div className={classes.root}>
            <LinearProgress
                classes={{
                    colorPrimary: classes.linearColorPrimary,
                    barColorPrimary: classes.linearBarColorPrimary
                }}
                className={classes.progress}
            />
        </div>
    );
}
export default withStyles(styles)(Progress);