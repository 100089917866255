import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import { withStyles } from "@material-ui/core/styles";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import PropTypes from 'prop-types';

const styles = theme => ({
    paginate: {
        display: "flex",
        "justify-content": "center",
        padding: 0,
        color: "#fff",
        "& li": {
            "& a": {
                ...theme.Link,
                display: "flex",
                "align-items": "center"
            },
            display: "flex",
            "padding-left": "15px",
            "padding-right": "15px"
        },
        "& ul": {
            display: "flex"
        },
        "& .active": {
            "border-style": "solid",
            "border-width": "1px"
        },
        "& .pagination": {
            padding: 0,
        }
    }
});

class Paginate extends Component {
    handlePageClick = data => {
        this.props.pageChange(data.selected);
        window.scrollTo(0, 0);
    };

    render() {
        const { classes } = this.props;
        const pageCount = Math.ceil(
            this.props.totalPhotos / this.props.itemsOnPage
        );

        return (
            <div className={classes.paginate}>
                <ReactPaginate
                    previousLabel={<KeyboardArrowLeft />}
                    nextLabel={<KeyboardArrowRight />}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    forcePage={this.props.page}
                />
            </div>
        );
    }
}
export default withStyles(styles)(Paginate);

Paginate.propTypes = {
    pageChange: PropTypes.func,
    itemsOnPage: PropTypes.number,
    totalPhotos: PropTypes.number
}
